import React, { Component } from 'react';
import { Link } from "gatsby";

export default class Footer extends Component {
    render() {
        return (
            <div className="app-footer px-xl-0 pr-0">
                <div className="container">
                    <div className="app-copyright">
                        © Copyright 2020, <Link to="/">Andesh Chogle</Link>. All Rights Reserved.
                    </div>
                    <div className="app-credits">
                        Design heavily inspired by (aka shamelessly copied from) <a href="//www.hanselman.com/blog" rel="noopener">Scott Hanselman's blog</a>.
                        <br /><br />
                    </div>
                </div>
            </div>
        );
    }
}

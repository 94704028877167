import React, { Component } from 'react';
import { Link } from "gatsby"


export default class Header extends Component {
    render() {
        let location = this.props.location;
        var menuItem = "home";

        if (location.pathname.startsWith("/blog")) {
            menuItem = "blog";
        } else if (location.pathname.startsWith("/resume")) {
            menuItem = "resume";
        }

        return (
            <div className="app-header px-xl-0 pr-0">
                <div className="container">
                    <nav className="row navbar navbar-expand-sm navbar-light bg-light px-0">
                        <Link to={"/"} className="app-header-title"><h1>Andesh Chogle</h1></Link>
                        <div className="app-header-menu collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav d-flex justify-content-end">
                                <li className={"nav-item p-2 " + (menuItem === "home" ? "active" : "") }>
                                    <Link to="/" className="nav-link">About Me</Link>
                                </li>
                                <li className={"nav-item p-2 " + (menuItem === "blog" ? "active" : "") }>
                                    <Link to="/blog" className="nav-link">Blog</Link>
                                </li>
                                <li className={"nav-item p-2 " + (menuItem === "resume" ? "active" : "") }>
                                    <Link to="/resume" className="nav-link">Resume</Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}

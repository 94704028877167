import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

class Home extends React.Component {
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title;
        const homeData = data.markdownRemark;

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO title="Home" />
                <article className="home-content container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <h1 className="headline">{homeData.frontmatter.headline}</h1>
                            <div>
                                <img className="my-photo" src={data.avatar.childImageSharp.fixed.src} alt="Andesh Chogle" />
                                <span className="my-intro" dangerouslySetInnerHTML={{ __html: homeData.html }} />
                            </div>
                        </div>
                    </div>
                </article>
            </Layout>
        )
    }
}

export default Home

export const pageQuery = graphql`
  query ($path: String!){
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 600, height: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
        html
        frontmatter {
          title
          keywords
          headline
        }
      }
  }
`

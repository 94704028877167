import React from "react";

import 'bootstrap/dist/css/bootstrap.css';
import "../../static/css/index.scss";
import Helmet from "react-helmet";

import Header from "./header";
import Footer from "./footer";

class Layout extends React.Component {
    render() {
        const { location, children } = this.props
        
        return (
            <div>
                <Helmet>
                    <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&amp;display=swap" rel="stylesheet" />
                </Helmet>
                <Header location={location} />
                <main className="app-maincontent">{children}</main>
                <Footer />
            </div>
        )
    }
}

export default Layout
